import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VBottomSheet,{attrs:{"persistent":""},model:{value:(_vm.bottom_sheet),callback:function ($$v) {_vm.bottom_sheet=$$v},expression:"bottom_sheet"}},[_c(VForm,{ref:"myForm",on:{"submit":function($event){$event.preventDefault();return _vm.addNewStoreType.apply(null, arguments)}}},[_c(VSheet,{attrs:{"height":"300px"}},[_c(VRow,[_c(VCol,[_c(VBtn,{staticClass:"float-right",attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c(VIcon,[_vm._v("mdi-close-circle")])],1)],1)],1),_c(VRow,{staticClass:"ml-12"},[_c(VCol,{staticClass:"text-center mx-auto",attrs:{"cols":"12","lg":"4"}},[_c(VTextField,{attrs:{"label":"Enter new Store Type"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c(VBtn,{staticClass:"float-right",on:{"click":function($event){return _vm.addNewStoreType()}}},[_vm._v(" "+_vm._s(_vm.STORE_TYPE_DETAILS_BOTTOMSHEET)+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }