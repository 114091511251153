import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"800","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"card"},[_c(VCardTitle,{staticClass:"card-title"},[_vm._v("Applicants Details")]),_c(VCardText,[_c(VCarousel,{model:{value:(_vm.currentCarouselIndex),callback:function ($$v) {_vm.currentCarouselIndex=$$v},expression:"currentCarouselIndex"}},_vm._l((_vm.imagesData),function(imageData,index){return _c(VCarouselItem,{key:index},[_c('div',{staticClass:"image-container"},[_c(VCardText,[_vm._v(_vm._s(imageData.name))]),_c(VImg,{staticClass:"image",attrs:{"contain":"","src":imageData.base64img}})],1)])}),1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"red","text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v("close")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }