import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{staticStyle:{"background":"#c8e0f6"}},[_c(VCard,{staticClass:"card"},[_c(VCardTitle,{staticClass:"card-title"},[_vm._v("Amount to Add")]),_c(VCardText,[_c(VForm,{ref:"myForm",on:{"submit":function($event){$event.preventDefault();return _vm.topup.apply(null, arguments)}}},[_c(VTextField,{attrs:{"outlined":"","label":"Enter Amount","type":"number","hide-details":"auto"},model:{value:(_vm.topupAmount),callback:function ($$v) {_vm.topupAmount=$$v},expression:"topupAmount"}}),_c('h2',{staticClass:"mt-5"},[_vm._v("Add Payment Method")]),_c(VRadioGroup,{attrs:{"rules":_vm.rules.required},model:{value:(_vm.selectedPaymentMethod),callback:function ($$v) {_vm.selectedPaymentMethod=$$v},expression:"selectedPaymentMethod"}},[_c(VRow,[_c(VCol,[_c('div',{staticClass:"payment-info"},[_c(VCardTitle,[_vm._v("Debit or Credit Card")]),_c('img',{attrs:{"src":require('@/assets/payments_pic/visa.png'),"alt":"Visa Logo","width":"100","height":"50"}}),_c('img',{attrs:{"src":require('@/assets/payments_pic/masterCard.png'),"alt":"Visa Logo","width":"100","height":"50"}})],1)]),_c(VCol,[_c(VRadio,{staticClass:"float-right mr-5",attrs:{"value":"visa"}})],1)],1),_c(VRow,[_c(VCol,[_c('div',{staticClass:"payment-info"},[_c(VCardTitle,[_vm._v("PayMaya Wallet")]),_c('img',{attrs:{"src":require('@/assets/payments_pic/paymaya.png'),"alt":"PayMaya Logo","width":"50","height":"50"}})],1)]),_c(VCol,[_c(VRadio,{staticClass:"float-right mr-5",attrs:{"value":"paymaya"}})],1)],1),_c(VRow,[_c(VCol,[_c('div',{staticClass:"payment-info"},[_c(VCardTitle,[_vm._v("GCash")]),_c('img',{attrs:{"src":require('@/assets/payments_pic/gcash.png'),"alt":"GCash Logo","width":"50","height":"50"}})],1)]),_c(VCol,[_c(VRadio,{staticClass:"float-right mr-5",attrs:{"value":"gcash"}})],1)],1),_c(VRow,[_c(VCol,[_c(VBtn,{staticClass:"float-right",attrs:{"color":"#0c3a68","dark":""},on:{"click":_vm.topup}},[_vm._v("Next")])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }