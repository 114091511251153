import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"500","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"card"},[_c(VCardTitle,{staticClass:"card-title"},[_vm._v("Product")]),_c(VCardText,[_c(VForm,{ref:"myForm",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{staticClass:"mb-3",attrs:{"label":"Enter Product Name","rules":_vm.rules.required,"outlined":"","hide-details":"auto"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c(VTextField,{staticClass:"mb-3",attrs:{"label":"Enter Product Price","rules":_vm.rules.required,"outlined":"","hide-details":"auto"},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}}),_c(VTextField,{staticClass:"mb-3",attrs:{"label":"Enter Product Stock","rules":_vm.rules.required,"outlined":"","hide-details":"auto"},model:{value:(_vm.form.stock),callback:function ($$v) {_vm.$set(_vm.form, "stock", $$v)},expression:"form.stock"}}),_c(VFileInput,{attrs:{"label":"Input Image","accept":"image/*","rules":_vm.rules.required,"outlined":"","hide-details":"auto"},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"#0c3a68","text":""},on:{"click":function($event){return _vm.submit()}}},[_vm._v(_vm._s(_vm.PRODUCT_DIALOG))]),_c(VBtn,{attrs:{"color":"red","text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v("close")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }