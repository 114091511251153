import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{staticStyle:{"background":"#c8e0f6"}},[_c(VCard,{staticClass:"user-role"},[_c(VCard,{attrs:{"elevation":"2","outlined":""}},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.USER_ROLES},scopedSlots:_vm._u([{key:"item",fn:function({ item, index }){return [_c('tr',[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',_vm._l((item.RequirementDetails),function(RequirementDetail,i){return _c(VChip,{key:i,staticClass:"mr-1 mt-1",attrs:{"color":"primary"}},[_c('div',[_vm._v(" "+_vm._s(RequirementDetail.requirement_detailsName)+" ")])])}),1),_c('td',_vm._l((item.Accesses),function(Access,i){return _c(VChip,{key:i,staticClass:"mr-1 mt-1",attrs:{"color":"primary"}},[_c('div',[_vm._v(" "+_vm._s(Access.side_nav_name)+" ")])])}),1),_c('td',[_c(VIcon,{attrs:{"color":"orange"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")])],1)])]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }