import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{staticStyle:{"background":"#c8e0f6"}},[_c(VContainer,{staticClass:"container"},[_c('div',{staticClass:"page-title"},[_c('p',{staticClass:"text-h4"},[_vm._v("Admin")])]),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VCard,[_c('apexchart',{attrs:{"type":"pie","options":_vm.chartOptionsRoles,"series":_vm.seriesRoles}})],1)],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VCard,[_c('apexchart',{attrs:{"type":"pie","options":_vm.chartOptionsStatus,"series":_vm.seriesStatus}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }