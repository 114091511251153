import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VList,{attrs:{"dense":"","mini-variant":_vm.isMobile},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticClass:"logo text-center"},[_c('img',{attrs:{"src":require("../../assets/white-ep-2.png"),"alt":"Logo","width":"150"}})]),_c(VToolbarTitle,{staticClass:"text-center mb-3"},[_vm._v("E-Palengke")])],1),_vm._l((_vm.SIDE_NAV),function(item){return _c(VList,{key:item.id,class:{ 'active-item': _vm.isParentActive(item.name) }},[((!item.side_nav_children || item.side_nav_children.length === 0) && item.name != 'DELIVERY')?_c(VListItem,{attrs:{"to":{ name: item.name, params: { id: item.id } }}},[_c(VIcon,{staticClass:"ml-3 mr-3"},[_vm._v(_vm._s(item.mdi_icon))]),_vm._v(" "+_vm._s(item.name)+" ")],1):(item.name != 'DELIVERY')?_c(VListGroup,{attrs:{"value":_vm.isParentActive(item.name),"color":"white","active-class":"my-active-class"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemTitle,[_vm._v(" "+_vm._s(item.name)+" ")])]},proxy:true}],null,true)},_vm._l((item.side_nav_children),function(child){return (child.name != 'DELIVERY ORDERS')?_c(VListItem,{key:child.id,staticClass:"childSideNav",attrs:{"to":{ name: child.name, params: { id: child.id } }}},[_vm._v(" "+_vm._s(child.name)+" ")]):_vm._e()}),1):_vm._e()],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }