import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{staticStyle:{"background":"#c8e0f6"}},[_c(VCard,{attrs:{"elevation":"2","outlined":""}},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.APPLICANTS},scopedSlots:_vm._u([{key:"item",fn:function({ item, index }){return [_c('tr',[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(item.applicant_name))]),_c('td',[_c(VChip,{attrs:{"color":"primary"}},[_vm._v(_vm._s(item.status))])],1),_c('td',[_vm._v(_vm._s(item.user_role_name))]),_c('td',[_vm._v(_vm._s(item.gender))]),_c('td',[_c(VIcon,{on:{"click":function($event){return _vm.view(item.user_role_id)}}},[_vm._v("mdi-eye")]),(item.status === 'pending')?_c(VIcon,{attrs:{"text":"","color":"red"},on:{"click":function($event){return _vm.dissaprove(item.user_role_id)}}},[_vm._v("mdi-close")]):_vm._e(),(item.status === 'pending')?_c(VIcon,{attrs:{"text":"","color":"green"},on:{"click":function($event){return _vm.approve(item.user_role_id)}}},[_vm._v("mdi-check")]):_vm._e()],1)])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }