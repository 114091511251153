import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.PRODUCT_TABLE_VIEWER != 'STORE')?_c(VCard,{attrs:{"elevation":"2","outlined":""}},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.PRODUCT},scopedSlots:_vm._u([{key:"item",fn:function({ item, index }){return [_c('tr',[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_c(VChip,{attrs:{"color":"green","dark":""}},[_vm._v("₱"+_vm._s(item.price))])],1),_c('td',[_vm._v(_vm._s(item.stock < 0 ? 0 : item.stock))]),_c('td',[_c(VImg,{attrs:{"contain":"","src":item.base64img,"eager":"","width":"100"}})],1),(_vm.PRODUCT_TABLE_VIEWER === 'INVENTORY')?_c('td',[_c(VIcon,{attrs:{"color":"orange"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c(VIcon,{attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])],1):_vm._e(),(
              _vm.PRODUCT_TABLE_VIEWER === 'STORE' && _vm.store_id !== item.store_id
            )?_c('td',[_c(VTextField,{attrs:{"label":"QTY","type":"number"},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}}),(item.stock > 0)?_c(VBtn,{staticClass:"mb-2",on:{"click":function($event){return _vm.addToCart(item)}}},[_c(VIcon,[_vm._v("mdi-cart-plus")])],1):_c(VBtn,[_c(VIcon,[_vm._v("mdi-alert-circle-outline")])],1)],1):_c('td')])]}}],null,false,232385957)})],1):_vm._e(),(_vm.PRODUCT_TABLE_VIEWER === 'STORE')?_c(VRow,_vm._l((_vm.PRODUCT),function(item,index){return _c(VCol,{key:index,attrs:{"cols":"12","md":"4"}},[_c(VCard,{staticClass:"card",attrs:{"outlined":""}},[_c(VImg,{attrs:{"contain":"","src":item.base64img,"eager":"","width":"500","height":"200"}}),_c(VCardTitle,[_vm._v(_vm._s(item.name))]),_c(VCardText,[_c('h3',[_vm._v("Price: ₱"+_vm._s(item.price))]),(
              _vm.PRODUCT_TABLE_VIEWER === 'STORE' && _vm.store_id !== item.store_id
            )?_c('h3',[_vm._v(" Stock: "+_vm._s(item.stock < 0 ? 0 : item.stock)+" ")]):_c('h3',[_vm._v("Stock: "+_vm._s(item.stock))])]),(_vm.store_id !== item.store_id)?_c(VCardActions,[(item.stock > 0)?_c(VBtn,{staticClass:"cart-btn",attrs:{"color":"#0c3a68","dark":""},on:{"click":function($event){return _vm.addToCart(item)}}},[_vm._v(" Add To Cart ")]):_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,[_vm._v("mdi-alert-circle-outline")])],1)],1):_vm._e()],1)],1)}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }