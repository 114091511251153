import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{staticStyle:{"background":"#c8e0f6"}},[_c(VContainer,{staticClass:"container"},[_c('div',{staticClass:"page-title"},[_c('p',{staticClass:"text-h4"},[_vm._v("Home")])]),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,[_c('apexchart',{attrs:{"type":"bar","options":_vm.chartOptionsOrders,"series":_vm.seriesOrders}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }