import { render, staticRenderFns } from "./CustomerOrders.vue?vue&type=template&id=f9218636&scoped=true&"
import script from "./CustomerOrders.vue?vue&type=script&lang=js&"
export * from "./CustomerOrders.vue?vue&type=script&lang=js&"
import style0 from "./CustomerOrders.vue?vue&type=style&index=0&id=f9218636&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9218636",
  null
  
)

export default component.exports