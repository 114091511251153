<template>
  <v-app>
    <Registration />
  </v-app>
</template>

<script>
import Registration from "../components/Registration.vue";

export default {
  components: { Registration },
};
</script>
