import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{staticClass:"app_container"},[_c('div',[_c(VBtn,{attrs:{"plain":"","color":"#FFFFFF"},on:{"click":_vm.landingPage}},[_c(VIcon,[_vm._v("mdi-arrow-left")]),_vm._v(" Back to Home ")],1)],1),_c(VContainer,[_c(VCard,{staticClass:"login-card text-center"},[_c('div',[_c('img',{attrs:{"src":require("../../assets/ep-only.png"),"alt":"E-Palengke Logo","width":"150"}})]),_c('p',[_vm._v("Log in to your account")]),_c(VForm,{ref:"myForm",on:{"submit":function($event){$event.preventDefault();}}},[_c(VTextField,{staticClass:"mb-4",attrs:{"label":"Username","rules":_vm.rules.required,"outlined":"","hide-details":"auto"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c(VTextField,{attrs:{"label":"Password","type":"password","rules":_vm.rules.required,"outlined":"","hide-details":"auto"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c(VBtn,{staticClass:"mt-2",attrs:{"plain":""},on:{"click":function($event){return _vm.openDialogForgot()}}},[_vm._v("Forgot password")]),_c('div',{staticClass:"mt-2 mb-2"},[_c(VBtn,{staticClass:"login-btn",attrs:{"color":"#0c3a68","dark":"","large":""},on:{"click":_vm.login}},[_vm._v("Login")])],1),_c(VBtn,{attrs:{"plain":""},on:{"click":_vm.registration}},[_vm._v("Registration")]),_c(VDivider,{staticClass:"mt-2 mb-2"}),_c(VBtn,{attrs:{"color":"primary","plain":""},on:{"click":_vm.mobile}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-qrcode")]),_vm._v(" Download Mobile APK ")],1)],1),(_vm.GET_DIALOG_VAL_FORGOT == true)?_c('forgotDialog'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }