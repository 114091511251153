import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[(_vm.ORDERS_TABLE_MODE === 'store')?_c(VDataTable,{attrs:{"headers":_vm.store_headers,"items":_vm.ORDER_DETAILS},scopedSlots:_vm._u([{key:"item",fn:function({ item, index }){return [_c('tr',[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_c(VChip,{attrs:{"color":"#0c3a68","dark":""}},[_vm._v(_vm._s(item.status))])],1),_c('td',[_vm._v(_vm._s(item.quantity))]),_c('td',[_c(VChip,{attrs:{"color":"green","dark":""}},[_vm._v("₱"+_vm._s(item.price))])],1),_c('td',[_c(VChip,{attrs:{"color":"green","dark":""}},[_vm._v("₱"+_vm._s(item.price * item.quantity))])],1)])]}}],null,false,1975222777)}):(_vm.ORDERS_TABLE_MODE === 'customer')?_c(VDataTable,{attrs:{"headers":_vm.SELECTED_ORDER_DETAILS.status === 'Pending'
        ? _vm.customer_headers
        : _vm.customer_headers2,"items":_vm.ORDER_DETAILS},scopedSlots:_vm._u([{key:"item",fn:function({ item, index }){return [_c('tr',[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.store_name))]),_c('td',[_vm._v(_vm._s(item.address))]),_c('td',[_vm._v(_vm._s(item.quantity))]),_c('td',[_c(VChip,{attrs:{"color":"green","dark":""}},[_vm._v("₱"+_vm._s(item.price))])],1),_c('td',[_c(VChip,{attrs:{"color":"green","dark":""}},[_vm._v("₱"+_vm._s(item.price * item.quantity))])],1),(_vm.SELECTED_ORDER_DETAILS.status == 'Pending')?_c('td',[_c(VBtn,{on:{"click":function($event){return _vm.CANCEL_ORDER_DETAIL(item)}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1):_vm._e()])]}}])}):(_vm.ORDERS_TABLE_MODE === 'delivery')?_c(VDataTable,{attrs:{"headers":_vm.customer_headers,"items":_vm.ORDER_DETAILS},scopedSlots:_vm._u([{key:"item",fn:function({ item, index }){return [_c('tr',[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.store_name))]),_c('td',[_vm._v(_vm._s(item.address))]),_c('td',[_vm._v(_vm._s(item.quantity))]),_c('td',[_c(VChip,{attrs:{"color":"green","dark":""}},[_vm._v("₱"+_vm._s(item.price))])],1),_c('td',[_c(VChip,{attrs:{"color":"green","dark":""}},[_vm._v("₱"+_vm._s(item.price * item.quantity))])],1),_c('td')])]}}])}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }