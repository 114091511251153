import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"1000","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"card"},[_c(VCardTitle,{staticClass:"card-title"},[_vm._v("Order Detail")]),_c(VCardText,[(
            _vm.SELECTED_ORDER_DETAILS.status === 'To Receive' ||
            _vm.SELECTED_ORDER_DETAILS.transactions_status === 'To Pickup' ||
            _vm.SELECTED_ORDER_DETAILS.transactions_status === 'Dropped off'
          )?_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VContainer,[_c(VCard,[_c(VRow,[_c(VCol,[_c(VCardTitle,[_vm._v("Delivery")]),_c(VCardText,[_vm._v(" Name: "+_vm._s(_vm.TRANSACTION.delivery_name)+" "),_c('br'),_vm._v(" Contact: "+_vm._s(_vm.TRANSACTION.phone_number)+" ")])],1),_c(VCol,[(_vm.TRANSACTION.profile_pic_path == null)?_c(VImg,{staticClass:"mr-3 float-right",staticStyle:{"border-radius":"5rem"},attrs:{"src":require('@/assets/profilePic.jpg'),"max-width":"150"}}):_c(VImg,{staticClass:"mr-3 float-right",staticStyle:{"border-radius":"5rem"},attrs:{"src":_vm.TRANSACTION.base64img,"max-width":"150"}})],1)],1)],1)],1)],1)],1):_vm._e(),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('OrderDetailsTable')],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"red","text":""},on:{"click":_vm.closeDialog}},[_vm._v("Close")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }