import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"1000","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"card"},[_c(VCardTitle,{staticClass:"card-title"},[_vm._v("Cart")]),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VTextField,{staticClass:"float-left",attrs:{"readonly":"","outlined":"","hide-details":"auto"},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_vm._v(" "+_vm._s(_vm.currentDateTime)+" ")]},proxy:true}])})],1)],1),_c('CartTable'),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{attrs:{"outlined":"","readonly":"","hide-details":"auto"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_vm._v(" Balance: ₱"+_vm._s(_vm.USER_DETAILS.balance)+" ")]},proxy:true}])})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{attrs:{"outlined":"","readonly":"","hide-details":"auto"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_vm._v(" Total: ₱"+_vm._s(_vm.total.toFixed(2))+" ")]},proxy:true}])})],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"#0c3a68","loading":_vm.isOrdering,"dark":"","text":""},on:{"click":_vm.order}},[_vm._v("Check out")]),_c(VBtn,{attrs:{"color":"red","dark":"","text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v("Close")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }