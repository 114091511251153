import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,{staticClass:"mb-5",attrs:{"elevation":"2","outlined":""}},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.MY_APPLICATION},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',[_c('td',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(item.name))]),_c('td',{staticStyle:{"text-align":"center"}},[_c(VChip,{attrs:{"color":"primary"}},[_vm._v(_vm._s(item.status))])],1),_c('td',{staticStyle:{"text-align":"center"}},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.view(item)}}},[_c(VIcon,[_vm._v("mdi-eye")])],1)],1)])]}}])})],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"4"}},[(_vm.user_role.length !== 0)?_c(VAutocomplete,{attrs:{"items":_vm.user_role,"label":"Select Application","item-text":"name","item-value":"id","auto-select-first":"","chips":"","deletable-chips":"","outlined":"","hide-details":"auto"},on:{"change":_vm.handleUserRoleChange},model:{value:(_vm.selected_user_role),callback:function ($$v) {_vm.selected_user_role=$$v},expression:"selected_user_role"}}):_vm._e()],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},_vm._l((_vm.user_role_details),function(item,index){return _c(VFileInput,{key:index,staticClass:"mb-3",attrs:{"label":item.name,"accept":"image/*","rules":_vm.rules.required,"outlined":"","hide-details":"auto"},on:{"change":function($event){return _vm.handleFileChange()}},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }