import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"mt-5 mb-5",attrs:{"elevation":"2","outlined":""}},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.CART},scopedSlots:_vm._u([{key:"item",fn:function({ item, index }){return [_c('tr',{style:({ 'background-color': item.stock < 0 ? 'grey' : '' })},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.store_name))]),_c('td',[_vm._v("₱"+_vm._s(item.price))]),_c('td',[_vm._v(_vm._s(item.stock))]),_c('td',[_vm._v(_vm._s(item.quantity))]),_c('td',[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[(item.stock > 0)?_c(VBtn,{attrs:{"icon":"","color":"green"},on:{"click":function($event){return _vm.increaseItem(item)}}},[_c(VIcon,[_vm._v("mdi-plus")])],1):_c(VBtn,{attrs:{"icon":"","color":"orange"}},[_c(VIcon,[_vm._v("mdi-alert-circle-outline")])],1),_c(VBtn,{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.decreaseItem(item)}}},[_c(VIcon,[_vm._v("mdi-minus")])],1),_c(VBtn,{attrs:{"icon":"","color":"black"},on:{"click":function($event){return _vm.removeItem(item)}}},[_c(VIcon,[_vm._v("mdi-cart-off")])],1)],1)],1)],1)])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }