import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"500","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"card"},[_c(VCardTitle,{staticClass:"card-title"},[_vm._v("Product Type Details")]),_c(VCardText,[_c(VTextField,{attrs:{"label":"Enter New Product Type","rules":_vm.rules.required,"hide-details":"auto","outlined":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"#0c3a68","text":""},on:{"click":function($event){return _vm.handleOperation()}}},[_vm._v(" "+_vm._s(_vm.PRODUCT_TYPE_DETAILS_DIALOG)+" ")]),_c(VBtn,{attrs:{"color":"red","text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v("close")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }