import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{staticStyle:{"background":"#0c3a68"}},[_c(VContainer,[_c(VCard,{staticClass:"card text-center"},[_c('p',{staticClass:"card-title ma-0 pa-0"},[_vm._v("Scan QR Code")]),_c(VCardText,[_c(VImg,{staticClass:"mb-5",attrs:{"src":require("../../assets/mobileQR.png"),"alt":"E-Palengke Mobile QR CODE","width":"500"}}),_c('div',[_c('a',{attrs:{"href":"https://epalengke.shop/E-Palengke.apk"}},[_vm._v("Download Mobile APK")])]),_c(VBtn,{staticClass:"mt-2",attrs:{"plain":"","color":"primary"},on:{"click":_vm.login}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-arrow-left")]),_vm._v("Back to Login ")],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }