import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[(_vm.$route.meta.showSideMenuBar)?_c('div',[_c(VNavigationDrawer,{attrs:{"app":"","color":"primary","dark":"","width":_vm.drawerWidth},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('SideMenuBar')],1),_c(VAppBar,{attrs:{"app":"","elevation":"0","color":"#C8E0F6"}},[_c(VIcon,{staticClass:"ml-5 mr-2",attrs:{"color":"#0C3A68"},on:{"click":_vm.toggleSidebar}},[_vm._v("mdi-menu")]),_c(VSpacer),_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"text":""}},on),[_vm._v(" "+_vm._s(_vm.USER_DETAILS.name)+" "),_c('div',[_c('img',{staticClass:"mr-2",attrs:{"src":_vm.PROFILE_PATH,"alt":"Profile Picture","width":"50"}})])])]}}],null,false,840381114)},[_c(VList,[_c(VListItem,[_c(VBtn,{attrs:{"text":"","block":"","color":"error"},on:{"click":function($event){return _vm.submitLogout()}}},[_vm._v(" Logout ")])],1)],1)],1)],1)],1):_vm._e(),_c(VMain,[(_vm.routerViewVisible)?_c('router-view'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }