import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{staticStyle:{"background":"#c8e0f6"}},[_c('div',[_c(VBtn,{staticClass:"mb-5",attrs:{"color":"primary"},on:{"click":function($event){return _vm.addNewProductType()}}},[_vm._v(" add product type ")])],1),_c(VCard,{attrs:{"elevation":"2","outlined":""}},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.PRODUCT_TYPE_DETAIL},scopedSlots:_vm._u([{key:"item",fn:function({ item, index }){return [_c('tr',[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_c(VIcon,{attrs:{"color":"orange"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c(VIcon,{attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])],1)])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }